<template>
    <div>
      <!-- TRAİNER Cards -->


      <b-row v-if="(TrainersWithActivities !== null)">
        <b-col
          v-for="TrainerCard in TrainersWithActivities"
          :key="TrainerCard.TrainerId"
          :class=" 2 ? 'd-block p-1' : 'd-none'" md="4" sm="6">
          <card-advance-profile :TrainerCard="TrainerCard" />

        </b-col>
      </b-row>


    </div>
  
</template>

<script>

import  CardAdvanceProfile from '@/components/TrainerComponents/ActiveTrainer.vue'
import {
  BTabs,
  BTab,
  BCard,
  BCardGroup,
  BCardText,
  BButton,
  BButtonGroup,
  BPaginationNav,
  BAvatar,
  BBadge,
  BMediaAside,
  BLink,
  BImg,
  BProgress,
  BProgressBar,
  BRow,
  BCol,
  BCalendar,
  BCardHeader,
  BMedia,
  BMediaBody,
  BCardBody,
  BAlert,
} from "bootstrap-vue";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    CardAdvanceProfile,
    BTabs,
    BCardText,
    BTab,
    BCard,
    BCardGroup,
    BButton,
    BButtonGroup,
    BPaginationNav,
    BAvatar,
    BBadge,
    BMediaAside,
    BLink,
    BImg,
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BCalendar,
    BCardHeader,
    BMedia,
    BMediaBody,
    BCardBody,
    BAlert
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      TrainersWithActivities:[]
    };
  },
  methods: {
  trainersWithActivity() 
  {
    
    this.$database.TrainerService.trainers_with_activity(this.$store.getters["auth/userInfo"].company_branch_id)
      .then((res) => {


       


      if (res.IsSuccess === true || res.IsSuccess === 1) {
          res.Result.map((el) => {

              this.TrainersWithActivities.push(this.$models.trainerWithActivity(
                el.TrainerId, 
                el.UserId,
                el.PersonId,
                el.CompanyBranchId,
                el.FirstName,
                el.LastName,
                el.Email,
                el.PhoneNumber,
                el.Birthday,
                el.Gender,
                el.UserImg,
              ));
        })
        console.log(this.TrainersWithActivities);
      }
    });    
  }
},
  computed:{
  
  },
  watch:{
},
  created(){
    this.trainersWithActivity();
},
  mounted(){ 
}
}
</script>