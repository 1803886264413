import { render, staticRenderFns } from "./ActiveTrainer.vue?vue&type=template&id=71ca250b&scoped=true&"
import script from "./ActiveTrainer.vue?vue&type=script&lang=js&"
export * from "./ActiveTrainer.vue?vue&type=script&lang=js&"
import style0 from "@/styles/css/activiteTrainer.css?vue&type=style&index=0&id=71ca250b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ca250b",
  null
  
)

export default component.exports