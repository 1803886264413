<template>
    <b-card
      :img-src="require('@/assets/images/banner/run_personel.jpg')"
      img-alt="Profile Cover Photo"
      img-top
      class="card-profile"
    >
      <div class="profile-image-wrapper" >
        <div class="profile-image">
          <b-avatar  size=125   variant="light" :src="TrainerCard.UserImg" />
        </div>
      </div>
      <h3>{{TrainerCard.FirstName}} {{TrainerCard.LastName}}</h3>
      <h6 class="text-muted">
        Egitmen
      </h6>
      <!-- <b-badge
        class="profile-badge"
        variant="light-primary">
        Teknogym Sertifikalı
      </b-badge> -->
      <hr class="mb-2">
      <!-- follower projects rank -->
      <!-- <div class="d-flex justify-content-between align-items-center">
        <div>
          <h6 class="text-muted font-weight-bolder">
            Activite
          </h6>
          <h3 class="mb-0">
            5
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Tecrube
          </h6>
          <h3 class="mb-0">
          8
          </h3>
        </div>
        <div>
          <h6 class="text-muted font-weight-bolder">
            Seviye
          </h6>
          <h3 class="mb-0">
            7
          </h3>
        </div>
      </div> -->
      <!--/ follower projects rank -->
    </b-card>
  </template>
  
  <script>
  
  import { BCard, BAvatar, BBadge } from 'bootstrap-vue'
  
  export default {
    components: {
      BCard,
      BBadge,
      BAvatar,
    },
    props: {
    TrainerCard:{
    type:Object,
    required:true
  },
},
  }
  </script>
  
  <style src="@/styles/css/activiteTrainer.css" scoped > </style>